<template>
  <div class="artdetail">
    <Heador :headertitle="headertitle" class="archeader" />
    <p>{{ title }}</p>
    <section>
      <p>
        发布时间：
        <span>{{ publishedTime }}</span
        >&nbsp;
        <span class="iconfont icon-yanjing1"></span>
      </p>
    </section>
    <div v-html="content" class="artcontent"></div>
    <!-- 返回顶部组件 -->
  </div>
</template>

<script>
// 引入返回顶部组件
import Heador from "./header";
export default {
  data() {
    return {
      content: this.$route.query.content,
      headertitle: "详情",
      publishedTime: "",
      title: this.$route.query.title,
    };
  },
  components: {
    Heador,
  },
  created() {
    // this.$toast.loading({
    //   message: '加载中...',
    //   duration: 5000,
    //   forbidClick: true,
    //   loadingType: 'spinner'
    // });
    // this.getDetail();
    var d=new Date(Number(this.$route.query.publishedTime))
    this.formatDate(d)
  },

  methods: {
    formatDate(now) {
      var year = now.getFullYear();
      var month = now.getMonth() + 1;
      var date = now.getDate();
      this.publishedTime = year + "-" + month + "-" + date
    },
    // 获取文章内容
    getDetail() {
      // 获取传过来的文章ID
      this.$api.home
        .articleDetailAddress({
          articleId: this.$route.params.id,
        })
        .then((res) => {
          if (res.code == 100) {
            this.$toast.clear();
            this.content = res.returnValue.content;
            this.publishedTime = res.returnValue.publishedTime;
            this.title = res.returnValue.title;
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
.artdetail {
  padding: 1.28rem /* 64/50 */ 0 0.32rem /* 16/50 */;
  // background-color: #fff;
  .archeader {
    position: fixed;
    z-index: 99;
    top: 0;
    width: 100%;
  }
  & > section {
    padding: 0 0.28rem /* 14/50 */;
    p {
      text-align: right;
      line-height: 0.5rem /* 25/50 */;
      font-size: 0.28rem /* 14/50 */;
      color: rgb(128, 126, 126);
    }
  }
  & > p {
    color: black;
    text-indent: 0.32rem /* 16/50 */;
    line-height: 0.44rem /* 22/50 */;
    padding: 0 0.24rem /* 12/50 */;
    font-size: 0.32rem /* 16/50 */;
  }
}
</style>
<style lang='less'>
.artdetail {
  .artcontent {
    padding: 0 0.24rem /* 12/50 */;
    p {
      font-size: 0.28rem /* 14/50 */;
      line-height: 0.5rem /* 25/50 */;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>

